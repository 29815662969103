import { Cookies } from 'react-cookie';

import _get from 'lodash/get';
import _set from 'lodash/set';

import { withCtxMiddleware } from '@bonnet/next/ctx-middleware';

import addAdParameters from '@atc/bonnet-ctx-ad-params';
import { translate } from '@atc/bonnet-parameters';
import { pageNames } from '@atc/bonnet-paths';
import { getKbbDmaCode, getReference } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

import sfpAdsDuck from '@/ducks/sfp/sfpAdsDuck';
import sfpSelectedCriteriaDuck from '@/ducks/sfp/sfpSelectedCriteriaDuck';
import srpAdsDuck from '@/ducks/srp/srpAdsDuck';
import vdpAdsDuck from '@/ducks/vdp/vdpAdsDuck';

const { SEARCH_FORM, SEARCH_RESULTS, VEHICLE_DETAILS } = pageNames;

const NONE = 'none';

const stringElement = (o, code) => {
    const v = _get(o, code, '');
    if (typeof v === 'string') {
        return v;
    }
    return v[0];
};

const hasValue = (param) => !!param && param !== NONE;

// ensure that if we have multiple makes, that we only assign matching models/series
const matchToCode = (code = '', options = []) => {
    if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
            if (code === options[i].code) {
                return code;
            }
        }
    }
    return '';
};

// handle some model exceptions, for now it replaces 'clubcoupe' with 'regularcab' and replaces 'clubcab&chassis' with 'hdextendedcab'
const handleModelExceptions = (kbbName) => kbbName.replace(/clubcoupe/g, 'regularcab').replace(/clubcab&chassis/g, 'hdextendedcab');

// attempt to match the code to the options supplied, return code if no matches
const getKbbName = (code = '', options = []) => {
    if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
            if (code === options[i].code) {
                const kbbName = options[i].kbbName || options[i].name;
                // remove all spaces, dashes/hyphens, parenthesis & handle some model exceptions
                return handleModelExceptions(kbbName.replace(/\s|-|\(|\)/g, '').toLowerCase());
            }
        }
    }
    return code;
};

// attempt to match the code to the options supplied
const getKbbCode = (code = '', options = []) => {
    if (options && options.length > 0) {
        for (let i = 0; i < options.length; i++) {
            if (code === options[i].code) {
                const kbbName = options[i].kbbCode || options[i].code;
                // remove all spaces
                return kbbName.replace(/\s/g, '').toLowerCase();
            }
        }
    }
    return '';
};

// map body style to sub seg
const getAtcSubSeg = (code) => {
    const subseg = {
        AWD4WD: 'COMPACT_SUV_CUV',
        COMMERCIAL: 'TRUCKS',
        CONVERT: 'FULLSIZE_CAR',
        COUPE: 'FULLSIZE_CAR',
        HATCH: 'COMPACT_CAR',
        HYBEL: 'FULLSIZE_CAR',
        LUXURY: 'FULLSIZE_CAR',
        SEDAN: 'FULLSIZE_CAR',
        SUVCROSS: 'COMPACT_SUV_CUV',
        TRUCKS: 'TRUCKS',
        VANMV: 'FULLSIZE_SUV_CUV',
        WAGON: 'COMPACT_CAR',
    };
    return _get(subseg, code, NONE);
};

// map body style to kbb sub seg
const getKbbSubSeg = (code) => {
    const subseg = {
        AWD4WD: 'smlsc',
        COMMERCIAL: 'picup',
        CONVERT: 'mid',
        COUPE: 'mid',
        HATCH: 'sml',
        HYBEL: 'altf',
        LUXURY: 'luxc',
        SEDAN: 'mid',
        SUVCROSS: 'smlsc',
        TRUCKS: 'picup',
        VANMV: 'midsc',
        WAGON: 'sml',
    };
    return _get(subseg, code, NONE);
};

const getFuelTypeValue = (data, isVDP = false) => {

    if (!isVDP) {
        const { fuelTypeGroup, fuelTypeOptions } = data;
        if (fuelTypeGroup.length === 0) {
            return fuelTypeOptions.map((option) => option.value).join(',');
        }
        return fuelTypeGroup.toString();
    }

    let fuelType;
    const fuelTypeGroup = _get(data, 'group', '');
    if (fuelTypeGroup === 'Hybrid: Gas/Electric') {
        fuelType = 'HYB';
    } else if (fuelTypeGroup === 'Plug-in Hybrid: Gas/Electric') {
        fuelType = 'PIH';
    } else {
        const fuelTypeName = _get(data, 'name', '');
        const fuelTypeListCode = {
            Diesel: 'DSL',
            Gasoline: 'GSL',
            Electric: 'ELE',
            Hydrogen: 'HYD',
        };
        fuelType = _get(fuelTypeListCode, fuelTypeName, NONE);
    }

    return fuelType;
};

const getClientAddress = (headers = {}) => {
    const xForwardedfor = _get(headers, 'x-forwarded-for', '').split(',')[0];
    const remote = _get(headers, ['connection', 'remoteAddress'], '');
    let clientAddr = 'nl';
    if (xForwardedfor) {
        clientAddr = xForwardedfor;
    } else if (remote) {
        clientAddr = remote;
    }
    return clientAddr;
};

const getBehaviourialTarget = (dxatc) => {
    if (dxatc) {
        let btc = decodeURIComponent(dxatc);
        if (btc && btc.startsWith('=')) {
            btc = btc.slice(1);
        }
        return btc;
    }
    return 'nl';
};

const assignAdState = (params, adState, serverSide, isBot) => {
    Object.assign(params, { anml: isBot ? 'y' : 'n' });
    if (!serverSide) {
        Object.assign(params, { pixallId: _get(adState, ['pageLevelTargeting', 'pixall'], '') });
        Object.assign(params, { language: _get(adState, ['pageLevelTargeting', 'lng'], '') });
        Object.assign(params, { betaGroup: _get(adState, ['pageLevelTargeting', 'betagrp'], '') });
        Object.assign(params, { mcmid: _get(adState, ['pageLevelTargeting', 'mcmid'], '') });
        Object.assign(params, { ip: _get(adState, ['pageLevelTargeting', 'ip'], '') });
        Object.assign(params, { btc: _get(adState, ['pageLevelTargeting', 'btc'], '') });
        Object.assign(params, { os: _get(adState, ['pageLevelTargeting', 'os'], '') });
        Object.assign(params, { uuid: _get(adState, ['pageLevelTargeting', 'uuid'], '') });
    }
};

// add kbb params to every brand
const getKbbAdParams = (params, optionalParams, makeCode, makeCodes, modelCode, modelCodes, seriesCode, seriesCodes, vehicleStyleCode, vehicleStyleCodes) => {
    const kbbMakeCode = makeCode || NONE;
    const kbbMake = getKbbName(kbbMakeCode, makeCodes);

    const kbbModelCode = modelCode || seriesCode || NONE;
    const kbbModelCodes = !modelCode && seriesCode ? seriesCodes : modelCodes;

    const kbbSeriesPrefix = !modelCode && seriesCode ? `${kbbMake}_`.toLowerCase() : '';

    Object.assign(params, { kmake: kbbMake });
    Object.assign(params, { kmod: kbbSeriesPrefix + getKbbName(kbbModelCode, kbbModelCodes) });
    Object.assign(params, { kbody: getKbbCode(vehicleStyleCode, vehicleStyleCodes) || NONE });
    Object.assign(params, { ksubseg: getKbbSubSeg(vehicleStyleCode) });
    Object.assign(params, { mdlid: getKbbCode(kbbModelCode, kbbModelCodes) });
    Object.assign(params, { cat: getKbbCode(vehicleStyleCode, vehicleStyleCodes) || NONE });
    Object.assign(optionalParams, { subcat: getKbbSubSeg(vehicleStyleCode) });
};

const assignKbbPageParams = (params, pageName, optionalParams, translatedQuery, adUnit) => {
    const listingType = _get(translatedQuery, 'listingType', '[NEW]');
    const makeCode = stringElement(params, 'makeCode');
    const modelCode = stringElement(params, 'modelCode');
    const seriesCode = stringElement(params, 'seriesCode');
    const type = _get(params, 'type', '');

    const kbbAdUnitSrp = `${adUnit}/srp`;
    const kbbAdUnitVdp = `${adUnit}/vdp`;

    switch (pageName) {
        case 'vdp':
            Object.assign(params, { adUnit: kbbAdUnitVdp });
            Object.assign(params, { sec: 'classifieds' });
            Object.assign(params, { ss: 'classifieds' });
            Object.assign(optionalParams, { pl: 'md' });
            if (type.toLowerCase() === 'new') {
                Object.assign(params, { dpg: 'classdetailnew' });
                Object.assign(params, { pg: 'classdetailnew' });
            } else {
                Object.assign(params, { dpg: 'classdetailused' });
                Object.assign(params, { pg: 'classdetailused' });
            }
            break;
        case 'srp': {
            Object.assign(params, { adUnit: kbbAdUnitSrp });
            Object.assign(params, { sec: 'classifieds' });
            Object.assign(params, { ss: 'classifieds' });
            if (listingType.includes('NEW')) {
                Object.assign(params, { dpg: 'classlistnew' });
                Object.assign(params, { pg: 'classlistnew' });
            } else {
                Object.assign(params, { dpg: 'classlistused' });
                Object.assign(params, { pg: 'classlistused' });
            }
            if (hasValue(modelCode) || hasValue(seriesCode)) {
                Object.assign(optionalParams, { pl: 'md' });
            } else if (hasValue(makeCode)) {
                Object.assign(optionalParams, { pl: 'mk' });
            }
            break;
        }
        default:
            break;
    }
};

const getDataIsland = (data) => {
    const dataIsland = _get(data, 'dataIsland');
    if (dataIsland) {
        return dataIsland;
    }
    return _get(data, ['pageData', 'dataIsland'], {});
};

const getDeviceType = (isMobile, isTablet, isKbb = false) => {
    let deviceType;
    if (isTablet === 'true') {
        deviceType = isKbb ? 'browser_tablet' : 'tablet';
    } else if (isMobile === 'true') {
        deviceType = isKbb ? 'browser_phone' : 'phone';
    } else {
        deviceType = isKbb ? 'browser_unk' : 'desktop';
    }
    return deviceType;
};

const getMcmid = (code = '') => code.match('MCMID%7C(.*?)%7C');

// if model exists, and it belongs to series, return the series
const getModelSeries = (modelCode, modelCodes) => {
    if (modelCode && modelCodes && modelCodes.length > 0) {
        for (let i = 0; i < modelCodes.length; i++) {
            if (modelCode === modelCodes[i].code) {
                return _get(modelCodes[i], 'classSeries.code', NONE);
            }
        }
    }
    return NONE;
};

/* for srp & sfp check to see if a series is selected, or is in the query, or exists as a parent of a model selection */
const getSearchPageSeriesCode = ({ modelCode, modelCodes, seriesCode, seriesCodes, translatedQuery }) => matchToCode(seriesCode, seriesCodes) || stringElement(translatedQuery, 'seriesCode') || getModelSeries(modelCode, modelCodes);

/*
 * * Dependent on getSearchPageSeriesCode() * *
 * for srp & sfp check to see if a model is selected, or is in the query or if getSearchPageSeriesCode returned a
 * series code as model code parent
 */
const getSearchPageModelCode = ({ modelCode, modelCodes, seriesCodeParam, translatedQuery }) => matchToCode(modelCode, modelCodes) || stringElement(translatedQuery, 'modelCode') || seriesCodeParam;

const getStandardParams = async (params, dataIsland, headers, cookies, brand, tq) => {
    const zip = _get(tq, 'zip', '');
    // TODO: BONNET NEXT - we need to remove this hardcoded basePath reference
    const kbbDmaCode = (brand === brands.KBB_BRAND && zip) ? await getKbbDmaCode(zip, 'cars-for-sale') : 'nl';
    const dmaCode = brand !== brands.KBB_BRAND ? _get(tq, 'dma.code', 'nl') : kbbDmaCode;

    const akamai = new Cookies(_get(headers, 'x-akamai-device-characteristics', {}));
    const isMobile = _get(akamai, ['cookies', 'is_mobile'], 'false');
    const isTablet = _get(akamai, ['cookies', 'is_tablet'], 'false');
    const osid = _get(cookies, ['cookies', 'SessionId'], _get(cookies, ['cookies', 'kbbSessionId', '']));
    Object.assign(params, { osid: osid || _get(tq, 'osid', 'nl') });
    Object.assign(params, { pgInst: _get(dataIsland, ['page', 'BIRF', 'pg_inst'], 'nl') });
    Object.assign(params, { language: _get(headers, 'accept-language', 'en').substring(0, 2) });
    Object.assign(params, { ip: getClientAddress(headers) });
    Object.assign(params, { betaGroup: _get(cookies, ['cookies', 'exp'], '').replace(/~/g, '|') });
    Object.assign(params, { mcmid: getMcmid(_get(cookies, ['cookies', 'AMCV_A9D33BC75245B2650A490D4D%40AdobeOrg'])) || 'nl' });
    Object.assign(params, { btc: getBehaviourialTarget(_get(cookies, ['cookies', 'dxatc'], '')) });
    Object.assign(params, { os: _get(akamai, ['cookies', 'device_os'], 'nl') });
    Object.assign(params, { uuid: _get(cookies, ['cookies', 'aam_uuid'], 'nl') });
    Object.assign(params, { dma: dmaCode });
    Object.assign(params, { device: getDeviceType(isMobile, isTablet, brand === brands.KBB_BRAND) });
    Object.assign(params, { maxPrice: _get(tq, 'maxPrice', 'nl') });
    Object.assign(params, { minPrice: _get(tq, 'minPrice', 'nl') });
    Object.assign(params, { dyn: _get(tq, 'dyn', 'nl') });
    Object.assign(params, { zip: _get(tq, 'zip', 'nl') });
    Object.assign(params, { state: _get(tq, 'state', 'nl') });
    Object.assign(params, { lnx: _get(tq, 'lnx', _get(tq, 'LNX', 'nl')) });
};

const getVdpListingParams = (params, tq, listing) => {
    const year = _get(listing, 'year', '').toString();
    const fuelTypeData = _get(listing, 'fuelType', {});

    Object.assign(params, { startYear: year || 'nl' });
    Object.assign(params, { qStartYear: _get(tq, 'startYear', 'nl') });
    Object.assign(params, { qEndYear: _get(tq, 'endYear', 'nl') });
    Object.assign(params, { makeCode: _get(listing, 'makeCode', '') });
    Object.assign(params, { modelCode: _get(listing, 'modelCode', '') || _get(listing, 'classSeriesCode', '') });
    Object.assign(params, { seriesCode: _get(listing, 'classSeriesCode', '') });
    Object.assign(params, { vehicleStyleCode: stringElement(listing, 'bodyStyleCodes') });
    Object.assign(params, { asubseg: getAtcSubSeg(stringElement(listing, 'bodyStyleCodes')) });
    Object.assign(params, {
        type: _get(listing, 'listingType', '').toString().toUpperCase()
            || _get(listing, 'type', '').toString().toUpperCase(),
    });
    Object.assign(params, { list: _get(listing, 'type', '').toLowerCase() });
    Object.assign(params, { listing_id: _get(listing, 'id', '').toString() });
    Object.assign(params, { ownid: _get(listing, 'ownerId', '').toString() });
    Object.assign(params, { kvid: _get(listing, 'kbbVehicleId', '').toString() });
    Object.assign(params, { fueltype: getFuelTypeValue(fuelTypeData, true) });
    Object.assign(params, { certptnr: _get(listing, 'certifiedProduct.id', 'nl') });
};

const getPageAdParams = (params, pageName, state, serverSide, adUnit, adPlacement, bidder, isBot) => {
    const placementId = _get(adPlacement, pageName, '');
    switch (pageName) {
        case 'vdp':
            Object.assign(params, { adUnit: `${adUnit}/vdp` });
            Object.assign(params, { placementId });
            Object.assign(params, { lazyLoadingOffset: { showCaseOffset: '-100', leaderBoardOffset: '-20' } });
            assignAdState(params, vdpAdsDuck.selectors.getDuckState(state), serverSide, isBot);
            Object.assign(params, { prebidAdapters: [{ bidder, params: { placementId } }] });
            break;
        case 'srp': {
            Object.assign(params, { adUnit: `${adUnit}/srp` });
            Object.assign(params, { adsLazyLoadingOffset: '-300' });
            Object.assign(params, { placementId });
            assignAdState(params, srpAdsDuck.selectors.getDuckState(state), serverSide, isBot);
            Object.assign(params, { prebidAdapters: [{ bidder, params: { placementId } }] });
            break;
        }
        case 'sfp':
            Object.assign(params, { adUnit: `${adUnit}/searchform` });
            Object.assign(params, { placementId });
            assignAdState(params, sfpAdsDuck.selectors.getDuckState(state), serverSide, isBot);
            break;
        default:
            break;
    }
};

const getBrandAdParams = (params, brand, pageName, query, cookies, optionalParams, translatedQuery, adUnit) => {
    const pixallabc = _get(cookies, ['cookies', 'pixall_abc'], '');
    const pxaid = _get(cookies, ['cookies', 'pxa_id'], '');

    switch (brand) {
        case brands.KBB_BRAND:
            Object.assign(params, { pixallId: pxaid });
            Object.assign(params, { siomid: _get(query, 'siomid', _get(cookies, ['cookies', 'siomid'], 'nl')) });
            Object.assign(params, { psid: _get(query, 'psid', _get(cookies, ['cookies', 'psid'], 'nl')) });
            assignKbbPageParams(params, pageName, optionalParams, translatedQuery, adUnit);
            break;

        default:
            Object.assign(params, { pixallId: pixallabc || pxaid });
    }
};

export async function getSfpAdParameters(state) {
    const query = sfpSelectedCriteriaDuck.selectors.getNormalizedCriteria(state);
    const dataIsland = state?.birf?.pageData;

    const params = {};
    const optionalParams = {};
    const translatedQuery = translate(query, 'lsc');

    const brand = brands.ATC_BRAND;

    const makeCode = stringElement(translatedQuery, 'makeCode');
    const modelCode = stringElement(translatedQuery, 'modelCode');
    const seriesCode = stringElement(translatedQuery, 'seriesCode');

    const serverSide = false;
    const headers = {};
    const cookie = _get(headers, 'cookie', {});
    const cookies = new Cookies(cookie);
    Object.assign(params, { startYear: _get(translatedQuery, 'startYear', 'nl') });
    Object.assign(params, { endYear: _get(translatedQuery, 'endYear', 'nl') });
    Object.assign(params, { makeCode });

    if (makeCode) {
        const { payload: modelCodes } = makeCode ? await getReference('modelCode', { makeCode }) : {};
        const { payload: seriesCodes } = makeCode ? await getReference('seriesCode', { makeCode }) : {};

        const seriesCodeParam = getSearchPageSeriesCode({ modelCode, modelCodes, seriesCode, seriesCodes, translatedQuery });
        const modelCodeParam = getSearchPageModelCode({ modelCode, modelCodes, seriesCodeParam, translatedQuery });

        Object.assign(params, { modelCode: modelCodeParam });
        Object.assign(params, { seriesCode: seriesCodeParam });
    }

    Object.assign(params, { vehicleStyleCode: stringElement(translatedQuery, 'vehicleStyleCode') });
    Object.assign(params, { asubseg: getAtcSubSeg(stringElement(translatedQuery, 'vehicleStyleCode')) });
    Object.assign(params, { type: _get(translatedQuery, 'listingType', 'all').toString() });

    await getStandardParams(params, dataIsland, headers, cookies, brand, translatedQuery);
    getPageAdParams(params, 'sfp', state, serverSide, '', '', '', false);
    getBrandAdParams(params, brand, 'sfp', query, cookies, optionalParams, translatedQuery);

    const fakeCtx = {
        data: {},
    };

    await withCtxMiddleware([
        addAdParameters({
            params,
        }),
    ], fakeCtx);

    return fakeCtx.data.ads;
}

export default function withAdParameters() {
    return async (ctx) => {
        const {
            req,
            pageName = '',
            query = {},
            data = {},
            store,
        } = ctx;
        const state = store.getState();

        const isBot = _get(state, 'userAgent.details.browser.robot', true);
        const params = {};
        const optionalParams = {};
        const translatedQuery = translate(query, 'lsc');

        // TODO remove migration & lsc flags after full migration to LSC
        const {
            ads: [, { adUnit, adPlacement, bidder }],
        } = ctx.useFeatures(['ads']);

        const { currentPageName } = state;
        if (currentPageName !== SEARCH_FORM) {
            // Read the LNX and pass it to pageLevelTargeting
            const lnx = _get(query, 'lnx', _get(query, 'LNX', 'nl'));

            _set(ctx.data.pageData, 'ad.pageLevelTargeting.lnx', lnx);
        }

        const isPageWithAds = [SEARCH_FORM, SEARCH_RESULTS, VEHICLE_DETAILS].includes(currentPageName);

        if (isPageWithAds) {
            // add a unique key that conveys the ad logic source is from find-car and not atc monolith
            Object.assign(optionalParams, { src: 'fyc' });

            const brand = _get(data, 'brand', 'atc');
            const listing = _get(data, 'base.listings[0]', '');

            // add key for lsc listing since it doesn't have the same keys with cs
            if (listing?.bodyStyles && !listing?.bodyStyleCodes) {
                listing.bodyStyleCodes = listing.bodyStyles.map((style) => style.code);
            }

            const makeCode = stringElement(translatedQuery, 'makeCode') || _get(listing, 'makeCode', '');
            const modelCode = stringElement(translatedQuery, 'modelCode') || _get(listing, 'modelCode', '');
            const seriesCode = stringElement(translatedQuery, 'seriesCode') || _get(listing, 'classSeriesCode', '');
            const vehicleStyleCode = stringElement(translatedQuery, 'vehicleStyleCode') || stringElement(translatedQuery, 'driveGroup')
                || stringElement(listing, 'bodyStyleCodes') || stringElement(listing, 'driveGroup');

            const { payload: makeCodes } = makeCode ? await getReference('makeCode') : {};
            const { payload: modelCodes } = makeCode ? await getReference('modelCode', { makeCode }) : {};
            const { payload: seriesCodes } = makeCode ? await getReference('seriesCode', { makeCode }) : {};
            const { payload: vehicleStyleCodes } = await getReference('vehicleStyleCode');

            const serverSide = _get(ctx, 'req', false);
            const headers = _get(req, 'headers', {});
            const cookie = _get(headers, 'cookie', {});
            const cookies = new Cookies(cookie);
            const dataIsland = getDataIsland(data); // TODO: replace once bonnet-ctx-data-island is compete

            await getStandardParams(params, dataIsland, headers, cookies, brand, translatedQuery);

            if (listing) {
                getVdpListingParams(params, translatedQuery, listing);
            } else {
                const seriesCodeParam = getSearchPageSeriesCode({ modelCode, modelCodes, seriesCode, seriesCodes, translatedQuery });
                const modelCodeParam = getSearchPageModelCode({ modelCode, modelCodes, seriesCodeParam, translatedQuery });
                const fuelTypeGroup = _get(translatedQuery, 'fuelTypeGroup', '');
                const fuelTypeOptions = _get(data, 'filters.fuelTypeGroup.options', []);

                Object.assign(params, { startYear: _get(translatedQuery, 'startYear', 'nl') });
                Object.assign(params, { endYear: _get(translatedQuery, 'endYear', 'nl') });
                Object.assign(params, { makeCode });
                Object.assign(params, { modelCode: modelCodeParam });
                Object.assign(params, { seriesCode: seriesCodeParam });
                Object.assign(params, { vehicleStyleCode });
                Object.assign(params, { asubseg: getAtcSubSeg(vehicleStyleCode) });
                Object.assign(params, { type: _get(translatedQuery, 'listingType', 'all').toString() });
                Object.assign(params, { alphaZoneCode: data?.alpha?.alphaZoneCode || '' });
                Object.assign(params, { fueltype: getFuelTypeValue({ fuelTypeGroup, fuelTypeOptions }) });
            }

            getPageAdParams(params, pageName, state, serverSide, adUnit, adPlacement, bidder, isBot);
            getBrandAdParams(params, brand, pageName, query, cookies, optionalParams, translatedQuery, adUnit);
            getKbbAdParams(params, optionalParams, makeCode, makeCodes, modelCode, modelCodes, seriesCode, seriesCodes, vehicleStyleCode, vehicleStyleCodes);

            if (optionalParams) {
                Object.assign(params, { optionalParams });
            }
            await withCtxMiddleware([
                addAdParameters({
                    params,
                }),
            ], ctx);

            if (ctx?.data?.pageData === undefined) {
                ctx.data.pageData = { ad: {} };
            }
            // TODO this is awful and we should stop setting one or both of these
            ctx.data.pageData.ad = { ...ctx.data.ads };
        }
    };
}
